.overall-container {
background-color: #d3d3d3;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-family: 'Assistant', sans-serif; 
}

.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #d3d3d3;
    color: black;
    font-family: 'Assistant', sans-serif;
    }

h4 {
    color: white;
}

.google-contact {
     display: flex;
     flex-direction: column;
     align-items: center;
     align-content: center;
    background: #fa236e;
    font-family: 'Assistant', sans-serif;
}

@media (max-width: 768px) {

    .overall-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: small;
      }

    .contact-container {
        display: flex;
        flex-direction: column;
        font-size: small;
    }

    .google-contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: small;
        /* height: auto; */
    }

}