.social-media-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100px;
    background-color: #d3d3d3;
    color: black;
    font-family: 'Assistant', sans-serif;
    font-weight: bold;
}

.intro {
    display: flex;
    flex-direction: column;
    justify-content: flex end;
    font-family: 'Assistant', sans-serif;
}

.social-media-icons li {
    display: inline-block;
    margin: 0 5px;
    width: 60px;
    height: 60px;
/* padding: -10px; */
}

.social-media-icons li a {
    display: block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    font-size: 20px;
    color: #fa236e;
    /* border-radius: 50%; */
}

.social-media-icons li p {
    padding:0;
}

.social-media-icons li a:hover {
    transform: translateX(-10px);
    color: #f94f8a;
    font-size: 65px;
}

.social-media-icons li a i {
    font-size: 20px;
}

@media (max-width: 768px) {
    .social-media-icons {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    }

    .social-media-icons li p {
        display: none;
    }
}