
.team-container {
    display: flex;
    flex-direction: grid;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
    gap: 10px 10px;
    font-family: 'Assistant', sans-serif;
    color: white;
}

.team-container h1 {
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.team-container h2 {
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-top: 0;
    margin-bottom: 0;
}

.team-container h3 {
    margin-top: 0;
    margin-bottom: 0;
    padding:0;
}

.team-container h4{
    padding:0;
    margin-top: 0;
    margin-bottom: 0;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: justify;
    padding: 2%;
    flex-grow: 1;
    flex-basis: 25%;
    border: #fa236e solid 3px;
    border-radius: 10px;
    background-color: black;
    color: white;
    
} 



.director-image {
    width: 200px;
    height: 200px;
}

@media (max-width: 768px) {
    .product-card {
        flex: 1 21%;
    }
}