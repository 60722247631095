
.about-overall-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    font-family: 'Assistant', sans-serif;
    min-height: 100%;
    background-color: #d3d3d3;
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  background-color: white;
  color: black;
  border:.4em solid #fa236e;
  min-height: 100%;
  margin-left: 500px;
  margin-right: 500px;
  padding: 10px;
}

.oriana-picture {
  height: 600px;
  width: 600px;
}

.oriana-picture1 {
  height: 600px;
  width: 600px;
}

@media (max-width: 768px) {
  .about-container {
    font-size: smaller;
  }
        .about-container {
          font-size: smaller;
          padding: .2rem;
          margin-left: auto;
          margin-right: auto;
          margin-top: auto;
        }

        .oriana-picture {
          height: 200px;
          width: 200px;
        }

          .oriana-picture1 {
          height: 200px;
          width: 200px;
        }
}





