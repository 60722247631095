
.registration-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    align-content:center;
    background: #d3d3d3;
    color: black;
    font-family: 'Assistant', sans-serif;
    min-height: 100vh;
    
}

.google-form {
    display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        background: black;
        color: var(--secondary-color);
        font-family: 'Assistant', sans-serif;
}

@media (max-width: 768px) {
    .registration-container {
        display: flex;
        flex-direction: column;
        font-size: small;
        font-family: 'Assistant', sans-serif;
    }

    .google-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:100%;
    }

}