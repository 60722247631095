.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Assistant', sans-serif;
    background-color: #585757 ;
    margin-top: auto;
    /* border-top: solid black; */
    color: black;
  
} 

.logo {
    display: flex;
    flex-direction: column;
    position: relative;

}

.links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
}

.links p {
    display: flex;
    justify-content: flex-start;
    margin-top: 0;
    margin-left: 40px;
}

.links ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    margin-top: 0;
}

.links li {
    flex-direction: column;
    justify-content: center;
    
}

.links li a {
    color: white;
}



.terms {
    display: flex;
    flex-direction: column;
    justify-content: column;
    font-weight: bold;
}

.terms p {
    display: flex;
    flex-direction: column;
}

.terms a{
    color: #fa236e;
}

.socials-links
{
    display: flex;
    flex-direction: column;
    font-weight: bold;
    
  
}

.socials-links ul {
    display: flex;
    flex-direction: column;
   
}

.socials-links a {
    display: flex;
    align-items:center ;
    padding: 5px;
    color: #fa236e;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}

@media (max-width: 768px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

        .links p {
            margin-left: 0px;
        }
}
