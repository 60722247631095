.blog-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    font-family: 'Assistant', sans-serif;
   background-color: black;
   color: white;
   text-align: center;
}

.blog-container h3 {
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title h2 {
    display: flex;
    flex-direction: column;
}

h2 h3 h4 p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

h4 {
    color: #fa236e;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.firstgroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video-november {
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        background-color: black;
        padding: 1px;
}

.video-november video {
    display: flex;
        flex-direction: column;
        justify-content: center;
        height: 50%;
        width: 50%;
}

.carousel-root {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.carousel {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    background-color: black;
    border: #d3d3d3;
    /* opacity: 90% ; */
}

.carousel .carousel-slider {
    display: flex;
    max-width: 100%;
} 

.carousel .slider-wrapper {
    width: 50%;
}

.carousel .slide {
    height:80%;
    width:80%;
}

.carousel .slide img {
    max-height: 100%;
}

.thumbs-wrapper axis-horizontal 

.carousel .thumb {
    display: flex;
    flex-wrap: wrap;
    background-color:#d3d3d3;
    border: #d3d3d3;
     
}

.carousel .thumb img {
    display: flex;
}

.may {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 100%;
        
}

.tutor-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: white;
}

.tutor-group p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding:0
}

@media screen and (max-width: 768px) {

    .blog-container {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: small;
    }

.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.title h2, h3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: medium;
}

    .carousel {
        display: flex;
        flex-direction: column;
        width: 100%;

    }

    .carousel img {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* height: auto; */
    }

    .carousel .thumb {
        display: none;
}

.firstgroup p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.may p {
    display: flex;
    justify-content: center;
    text-align: center;
    
}

.thumbs-wrapper axis-vertical {
    display: none;
}

.carousel button {
    display: none;
}


}
