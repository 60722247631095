.button {
    font-weight: bold;
    background-color: #d3d3dd;
    border: none;
    color: black;
    margin-left: 50%;
    margin-right: 50%;
}

.circle {
    width: 30px; 
    height: 30px; 
    border-radius:5

}