.homepage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: 'Assistant', sans-serif;
    padding: 1px;
    background-color: #d3d3d3;
    color: black;
}

.homepage-container h3 {
    color: black;
}

.homepage-container h2 u {
    color: #fa236e;
}

.welcome {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    color: white;
    background-image: url("../Assets/group2.jpeg");
    
}

.opening h1 {
    font-size: 40px;
    color: #fa236e;
    -webkit-text-stroke: 2px black;
}

.opening h2 {
    font-size: 30px;
    color: rgb(255, 166, 0);
    -webkit-text-stroke: 2px rgb(109, 3, 3);
}

.opening p {
    font-size: 18px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #d3d3d3;
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
            opacity: 0;
        }
    
    100% {
            opacity: 1;
        }
}

.updates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #d3d3d3;
    color: black;
    /* border-top:black solid; */
}

.updates a {
    color:#fa236e ;
}

.updates img {
    width: 30%;
    height: 30%;
    padding: 10px;
    color: rgb(255, 166, 0);
   border: #fa236e solid;
}


body {
    background-size: 300px 200px;
    background-repeat: repeat;
    background-position: center;
    font-family: 'Assistant', sans-serif;
    size: 14px;
    margin: 0;
    padding: 0;
    background-color: var(--text-color);
    background-color: #d3d3d3;
}

.video-source {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #d3d3d3;
    padding: 1px;
    color: white;
    text-align: center;
    /* border-top: black solid; */
}

.video-source h2 {
    color: black;
}

.video-source video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70%;
    width: 70%;
}


.shows-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #d3d3d3;
    color: black;
    font-family: 'Assistant', sans-serif;
}

.shows-container h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    color: black;
}

.posters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.posters img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
    border: #fa236e solid;
}
.shows-container img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 15%;
    height: 25%;
    margin-bottom: 20px;
    border: #fa236e solid;
}

.posters img:hover {
    width: 20%;
    height: 30%;
}

.showcase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: #d3d3d3;
    color: rgb(255, 166, 0);
    font-family: 'Assistant', sans-serif;
}

.showcase-container h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    font-family: 'Assistant', sans-serif;
}

.showcase-container h3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: 'Assistant', sans-serif;
    margin-bottom: 100px;
}

.socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -4rem;
    background-color: #d3d3d3;
    /* border: #d3d3d3 solid; */
}

.image-gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    background-color: #d3d3d3;
    /* border-top: solid black; */
    
}

.image-gallery img {
    display: flex;
    height: 400px;
    width: 400px;
    border: #fa236e solid;
}

@media (max-width: 768px) {
    .homepage-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: small;
        width: 100%;
    }

    .homepage-container h1 {
        font-size: 30px;
    }

    .opening p {
        font-size: small;
    }

    .updates h2 {
        font-size: medium;
    }

    .updates h3 {
        font-size: small;
    }

    .video-source {
        display: flex;
        flex-direction: column;
    }

    .video-source h2, h3 {
        font-size: medium;
    }

    .video {
        position: relative;
        width: 100%;
        max-height: none;
    }

    .shows-container {
        display: flex;
        flex-direction: column;
        font-size: small;
    }

    .shows-container h2 {
        font-size: medium;
    }

    .showcase-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .showcase-container h2,
    .showcase-container h3 {
        font-size: medium;
    }

    .posters {
        .posters img {
                width: calc(50% - 20px);
            }
    
    }

    .image-gallery {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: small;
        margin-top: 50px;
    }

    .image-gallery img {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 50%;
    }
}