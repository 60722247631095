
.box {
    display: flex;
    flex-direction: row;
}


.insta-feed {
    display: flex;
    flex-direction: row;
    align-content: center;
    max-width: 100%;
    gap:20px;
}


.embed1, .embed2, .embed3 {
    display: flex;
    border: solid #fa236e;
    justify-content: center;
    
}

@media (max-width: 900px){

    .insta-feed {
        display: flex;
        flex-direction: row;
    }

    .embed1, .embed2, .embed3 {
        display: flex;
        flex-direction: row;
  
    }

    .embed3 {
        display: none;
    }


 }

@media (max-width: 768px) {
    .embed1 {
        display: flex;
    }

    .embed2, .embed3 {
        display: none;
    }

}