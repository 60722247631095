
header {
    background-color: #585757;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 30px;
    margin-top: 0px; 
    z-index: 1;
    font-family: 'Assistant', sans-serif;
    font-weight: bold;
}

.logo img {
    /* padding: 30px; */
    max-width: 150px;
    height: 70px;
    position: relative;
    z-index: 2;
    margin-top: 25px;
}

.navbar {
    display: flex;
    align-items: row;
    align-items: center;
    margin-top: 40px;
}

.navbar li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    list-style: none;
    font-size: 16px;
}

.navbar li:first-child {
    margin-left: 0;
}

.navbar li a {
    color: black;
    text-decoration: none;
}

.navbar li a:hover {
    color: var(--accent-color);
}
@keyframes bannerFlash {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.navbar .active {
color:#fa236e;
}

.mobile-navbar {
    display: none !important;
    flex-direction: column;
    position: fixed;
    left: 40%;
    transform: translate(-50%, 0);
    top:20px;
    list-style: none;
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
    color: black;
   
}

.mobile-navbar ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    color: black;
    width: 100%;
    background-color: #585757;
}

.mobile-navbar li {
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid white;
    color: #fa236e;
}

.mobile-navbar li a {
color: white;
}

.navbar-mobile {
    width: 100%;
    color: black;
    background-color: #585757;
}

.icons {
    display: none !important;
    display: block;
    flex-direction: column;
    color: black;
    font-size: 20px;
}

.logo-mobile {
    display: none !important;
}


.buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
}
.registration-button a {
    display: flex;
        align-items: row;
        align-items: center;
        margin-top: 26px;
    background-color: var(--secondary-color);
    color: var(--text-color);
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    border: solid black 2px;
    margin-left: 10px;

}

.registration-button a:hover {
    background-color: black;
}

.storefront-button a {
    display: flex;
    align-items: row;
    align-items: center;
    margin-top: 26px;
    background-color: black;
    color: var(--text-color);
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    border: solid black 2px;
margin-right: 0;
}

.storefront-button a:hover {
    background-color: var(--accent-color);
}

.announcement-banner {
    background-color: black;
    color: var(--text-color);
    padding: 0px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height:100%; */
    animation-name: bannerFlash;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: initial;
    /* font-family: 'Permanent Marker', cursive; */
    font-family: 'Assistant', sans-serif;
}



@keyframes dancerAnimation {
    0% {
            transform: translateX(-100%);
            opacity: 40%;
        }
    
        20% {
            opacity: 30%;
        }
    
        80% {
            opacity: 20%;
        }
    
        100% {
            transform: translateX(100%);
            opacity: 0;
        }
    }


.dancers-container {
    display: flex;
    position: relative;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    pointer-events: none;
    margin-top: 3.2%;
    /* Prevents the container from capturing mouse events */
    z-index: 999;
    /* Ensures the container appears above other elements */
}

.dancer {
    /* position: absolute; */
    margin-right: 60px;
    top: 100%;
    /* transform: translateY(-50%); */
    animation-name: dancerAnimation;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: initial;
    animation-fill-mode: forwards;
}




/* Mobile Responsive */
@media screen and (max-width: 958px) {
    header {
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
        /* margin-top: 9%; */
    }
    .icons {
    display: flex !important;
    justify-content: flex-end;
    color: black ;
    margin-top: 20PX;
                }
    .logo {
    display: none !important;
    }

    .navbar {
       display: none !important;
    }

    .navbar li {
        margin-right: 0;
        margin-bottom: 10px;
        color: black;
    }

    .navbar li:first-child {
        margin-top: 10px;

    }


    .mobile-navbar {
        display: flex !important;
        flex-direction: column;
        position: inherit;
        justify-content: center;
        align-items: center;
        align-content: center;
        color: white;
        background-color: #585757;
        
    }

    .logo-mobile {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        margin-top: 35px;
        background-color: #d3d3d3;
        max-width: 100%;
    }

    .logo-mobile img {
        width: 100%;
        background-color: #585757;
    }

    .logo-mobile a {
        background-color: #585757;
    }


    .announcement-banner {
        display: flex;
        flex-direction: column;
    }

    .announcement-banner p {
        font-size: medium ;

    }
    .dancers-container {
        display:flex;
    }

    .buttons{
        display: none !important;
    }

}



